<template>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">                
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
					</template>
				</Toolbar>
				<DataTable ref="dt" :value="recursos" class="p-datatable-sm" dataKey="id_recurso" :paginator="true" :rows="10" :filters="filters"
							:globalFilterFields="['recurso', 'recurso_tipo']" 
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}" :loading="loadingGrd">
					<template #header>
						<div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
							<h5 class="p-m-0">Recursos</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="recurso" header="Recurso" :sortable="true" />
					<Column field="recurso_tipo"  header="Tipo" :sortable="true"/>													
					<Column field="activo" header="Activo">	
						<template #body="slotProps">
							<div class="p-jc-center">
								<i v-if="slotProps.data.activo=='S'" style="color: green;" class="pi pi-check"></i>										
							</div>
						</template>										
					</Column>
					<Column field="costo_reserva_privado"  header="Valor de Reserva" :sortable="true">
						<template #body="slotProps">
							<div v-if="slotProps.data.costo_reserva_privado > 0.0" class="p-d-flex p-jc-end" style="text-align: start;">
								<div>{{ slotProps.data.moneda_simbolo }}. {{ formatCurrency(slotProps.data.costo_reserva_privado) }}</div>
							</div>
							<div v-else class="p-d-flex p-jc-end">
								<div></div>
							</div>   											
						</template>							
					</Column>		
					<Column field="auto_generar_mov" header="Auto Generar">	
						<template #body="slotProps">
							<div class="p-jc-center">
								<i v-if="slotProps.data.auto_generar_mov" style="color: green;" class="pi pi-check"></i>										
							</div>
						</template>										
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-1" @click="edit(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning p-mr-1" @click="confirmDelete(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
			</div>

			<Dialog v-model:visible="editDialog" :style="{width: '450px'}" header="Recurso" :modal="true" :closable="true">			
				<div class="p-formgrid p-grid">
					<div class="p-field p-col-12 p-inputgroup p-ai-end">
						<div class="p-as-center" style="font-weight: bold;">Activo</div>
						<InputSwitch class="p-ml-3" v-model="recurso.activoDisplay"/>
					</div>
					<div class="p-field p-col-12 p-fluid">
						<label for="recurso" style="font-weight: bold;">Recurso</label>
						<InputText id="title" v-model="recurso.recurso" required="true"/>
					</div>
					<div class="p-field p-col-12 p-fluid">
						<label for="id_recurso_tipo" style="font-weight: bold;">Tipo</label>
						<Dropdown class="p-mr-2" v-model="recurso.id_recurso_tipo" :options="lkpTipos" optionLabel="recurso_tipo" optionValue="id_recurso_tipo"/>
					</div>
					<div class="p-field p-col-12">
						<label for="costo_reserva_privado" style="font-weight: bold;">Valor de Reserva</label>
						<div class="p-inputgroup">
							<InputNumber id="costo_reserva_privado" v-model="recurso.costo_reserva_privado" :minFractionDigits="2" :maxFractionDigits="2" :min="0"/>
							<span class="p-inputgroup-addon">
								<Dropdown class="" v-model="recurso.id_moneda" :options="lkpMonedas" optionLabel="id_moneda" optionValue="id_moneda"/>									
							</span>
						</div>							
					</div>
					<Panel class="p-field p-col-12" v-if="recurso.costo_reserva_privado > 0.0" header="Información para emisión de cargo por reserva">
						<div class="p-formgrid p-grid">
							<div class="p-field p-col-12 p-fluid" style="font-weight: bold;">
								<label for="id_producto_empresa">Producto</label>
								<Dropdown class="p-mr-2" v-model="recurso.id_producto_empresa" :options="lkpProductos" optionLabel="producto" optionValue="id_producto_empresa"/>
							</div>
							<div class="p-field p-col-6 p-fluid">
								<label for="id_movimiento_tipo" style="font-weight: bold;">Movimiento Tipo</label>
								<Dropdown class="p-mr-2" v-model="recurso.id_movimiento_tipo" :options="lkpMovsTipos" optionLabel="movimiento_tipo" optionValue="id_movimiento_tipo"/>
							</div>
							<div class="p-field p-col-6 p-fluid">
								<label for="id_movimiento_serie" style="font-weight: bold;">Serie</label>
								<Dropdown class="p-mr-2" v-model="recurso.id_movimiento_serie" :options="lkpMovTipoSerie" optionLabel="serie" optionValue="id_movimiento_serie"/>
							</div>
							<div class="p-field p-col-8 p-fluid">
								<label for="id_condicion_pago" style="font-weight: bold;">Condición de Pago</label>
								<Dropdown class="p-mr-2" v-model="recurso.id_condicion_pago" :options="lkpCondicionesPago" optionLabel="condicion_pago" optionValue="id_condicion_pago"/>
							</div>					
							<div class="p-field p-col-4" style="font-weight: bold;">
								<div style="font-weight: bold;">Auto Generar</div>
								<InputSwitch  class="p-mt-2" v-model="recurso.auto_generar_mov"/>
							</div>									
							<div class="p-field p-col-12 p-fluid">
								<label for="referencia" style="font-weight: bold;">Referencia</label>
								<InputText id="referencia" v-model="recurso.referencia" maxlength="100"/>
							</div>
						</div>
					</Panel>									
				</div>					
				<template #footer>
					<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="save"/>
					<Button label="Cerrar" icon="pi pi-refresh" class="p-button-text" @click="editDialog=false" />
				</template>
			</Dialog>

			<Dialog v-model:visible="enUsoDialog" :style="{width: '450px'}" header="En Uso" :modal="true">
				<div class="confirmation-content">
					<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
					<span v-if="recurso">No es posible remover el recurso <b>[{{ recurso.recurso }}]</b> por que tiene registros asociados!</span>
				</div>
				<template #footer>
					<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="enUsoDialog = false"/>						
				</template>
			</Dialog>

			<Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
				<div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="recurso">Esta seguro que desea eliminar el recurso seleccionado?<br><b>[{{ recurso.recurso }}]</b>?</span>
				</div>
				<template #footer>
					<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
					<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteRec" />
				</template>
			</Dialog>
		</div>
	</div>

</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import MdsService from '../../service/mds.service';
import InvService from '../../service/inv.service';
import {FilterMatchMode} from 'primevue/api';

export default {
	data() {
		return {
			lkpTipos: null,
			lkpMonedas: this.$store.state.auth.currentAppCtx.Monedas,
			lkpMovsTipos: this.$store.state.auth.currentAppCtx.tiposMovimientoInv,
			lkpMovTipoSerie: null,
			lkpCondicionesPago: this.$store.state.auth.currentAppCtx.CondicionesPago,
			lkpProductos: null,
			recursos: null,
			complejo: this.$store.state.auth.currentAppCtx.complejo.id_complejo,
			editDialog: false,
			deleteDialog: false,
			enUsoDialog: false,
			recurso: {},	
			loading: false,	
			loadingGrd: false,
			filters: {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}}
		}
	},
	components: {
		Loading
	},		
	async mounted() {
		this.loading = true;
			try {
				this.lkpTipos = [];
				var data = await MdsService.getRecursosReservaTipo(this.$store.state.auth.currentAppCtx);
				this.lkpTipos = data;

				data = await MdsService.getRecursosReserva(this.$store.state.auth.currentAppCtx, this.$store.state.auth.currentAppCtx.complejo.id_complejo, '0');
				this.recursos = data;

				data = await InvService.getProductos(this.$store.state.auth.currentAppCtx);
				this.lkpProductos = data;
				
				this.loading = false;	
			} catch (err) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err})
			}
	},
	watch: {
		'recurso.id_movimiento_tipo':  function (newVal, oldVal) {
			if(this.recurso.id_movimiento_tipo) {
				this.cargarSeriesXTipoMov();
			}
		}
	},	
	methods: {
		openNew() {
			this.recurso = {
				id_recurso: 0,
				id_complejo: this.$store.state.auth.currentAppCtx.complejo.id_complejo,
				recurso : null,
				activo: 'S',
				activoDisplay: true,
				id_recurso_tipo: this.lkpTipos[0].id_recurso_tipo,
				recurso_tipo: null,
				id_moneda: this.lkpMonedas[0].id_moneda,
				moneda_simbolo: null,
				costo_reserva_privado: 0.0,
				costo_reserva_publico: 0.0,
				id_producto_empresa: null,
				id_producto: null,
				codigo: null,
				producto: null,
				id_movimiento_tipo: this.lkpMovsTipos[0].id_movimiento_tipo,
				id_movimiento_serie: null,
				id_condicion_pago: this.lkpCondicionesPago[0].id_condicion_pago,
				condicion_pago: null,
				dias: 0,
				referencia: null,
				auto_generar_mov:false
			};			
			this.editDialog = true;			
		},
		formatCurrency(value) {
			return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
		},			
		edit(registro) {
			this.recurso = registro;
			this.recurso.activoDisplay = (this.recurso.activo == 'S');
			console.log(`Activo: ${this.recurso.activo} / ActivoDisplay: ${this.recurso.activoDisplay}`);
			this.editDialog = true;			
		},
		confirmDelete(registro) {
			this.recurso = registro;
			this.deleteDialog = true;
		},
		async save() {						
			try {
				this.loading = true;
				var esNuevo = (this.recurso.id_recurso == 0);
				this.recurso.activo = (this.recurso.activoDisplay?'S':'N');

				if(this.recurso.costo_reserva_privado == 0) {
					this.recurso.id_movimiento_tipo = '';
					this.recurso.id_movimiento_serie = '';
					this.recurso.id_producto_empresa = ''
					this.recurso.id_condicion_pago = '';										
					this.recurso.auto_generar_mov = false;
				}

				var data = await MdsService.RegistrarRecurso(this.$store.state.auth.currentAppCtx, this.recurso);

				if(esNuevo) {
					this.recurso.id_recurso = data.id;
					var recurso_tipo = this.lkpTipos.filter(t=>(t.id_recurso_tipo == this.recurso.id_recurso_tipo));
					var moneda = this.lkpMonedas.find((m)=> { return (m.id_moneda == this.recurso.id_moneda) });
					this.recurso.recurso_tipo = recurso_tipo[0].recurso_tipo;
					this.recurso.moneda_simbolo = moneda.simbolo;
					this.recursos.push(JSON.parse(JSON.stringify(this.recurso)));
				} else {					
					for (var i =0; i < this.recursos.length; i++) {
						if (this.recursos[i].id_contador == this.recurso.id_recurso) {
							this.recursos[i] = JSON.parse(JSON.stringify(this.recurso));							
							break;
						}
					}					
				}
				this.editDialog = false;	

				this.$toast.add({severity:'success', summary: 'Exito', detail: 'Los cambios han sido guardados con éxito!', life: 3000});									
				this.loading = false;				
			} catch (err) {
				this.loading = false;
				this.editDialog = false;	
				this.$toast.add({severity:'error', summary: 'Error', detail: err})
			}
		},		
		async deleteRec() {
			this.deleteDialog = false;
			try {
				var enUso = await MdsService.recursoEnUso(this.$store.state.auth.currentAppCtx, this.recurso);

				if(enUso) {
					this.$toast.add({severity:'warn', summary: 'Advertencia', detail: 'No es posible remover este recurso por que tiene reservas asociadas!', life: 3000})					
					return;
				}

				this.loadingGrd = true;
				await MdsService.RemoverRecurso(this.$store.state.auth.currentAppCtx, this.recurso);

				this.recursos = this.recursos.filter(val => val.id_recurso !== this.recurso.id_recurso);
				this.recurso = {};
				this.loadingGrd = false;
				this.$toast.add({severity:'success', summary: 'Exito', detail: 'El recurso ha sido removido!', life: 3000})

			} catch (err) {				
				this.loadingGrd = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})
			}			

		},
		async cargarSeriesXTipoMov() {
			try {
				this.loadingGrd = true;

				var mov_tipo = this.lkpMovsTipos.find((t)=> { return (t.id_movimiento_tipo == this.recurso.id_movimiento_tipo) });
				var series = await InvService.getSeriesTipoMovimiento(this.$store.state.auth.currentAppCtx, mov_tipo.id_movimiento_tipo_cat);

				this.lkpMovTipoSerie = series;

				if(this.lkpMovTipoSerie.length > 0) {
					if(!this.recurso.id_movimiento_serie) {
						this.recurso.id_movimiento_serie = this.lkpMovTipoSerie[0].id_movimiento_serie;
					}
				}
				this.loadingGrd = false; 
			} catch (err) {				
				this.loadingGrd = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})
			}					
		},		
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}
</style>
